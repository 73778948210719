<template>
  <div class="app-box">
    <div class="title-box">
      <div class="line"></div>
      <p class="title">科室列表</p>
    </div>
    <a-divider style="margin-left: -10px" />
    <a-row class="table-action-list" style="margin-top: 20px">
      <a-button type="primary" @click="add" style=" margin-left: 10px">+新增</a-button>
    </a-row>
    <a-table class="main-table" :pagination="{
      current: query.page,
      total: pagetotal,
      showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
    }" @change="listChange" :columns="columns" :data-source="data" rowKey="hospital_id" style="margin-top: 14px"
      v-viewer>
      <span slot="is_wd" slot-scope="text, record">
        {{ record.is_wd == 0 ? '关闭' : (record.is_wd == 1 ? '低温' : '高温') }}
      </span>
      <span slot="is_sound" slot-scope="text, record">
        <a-tag :color="record.is_sound === 1 ? '#74CE78' : '#FF0000'">
          {{ record.is_sound === 1 ? '开启' : '关闭' }}
        </a-tag>
        <a @click="changeSound(record.department_id)">{{ record.is_sound === -1 ? '开启' : '关闭' }}</a>
      </span>
      <span slot="action" slot-scope="text, record">
        <a-space>
          <a @click="toEdit(record)">编辑</a>
          <a-popconfirm title="确认删除该科室" ok-text="是" cancel-text="否" @confirm="del(record.department_id)">
            <a style="color: #E0352B">删除</a>
          </a-popconfirm>
          <a-popconfirm title="WiFi账密推送" ok-text="是" cancel-text="否" @confirm="push(record.department_id)">
            <a style="color: #E0352B">推送WiFi密码</a>
          </a-popconfirm>
        </a-space>
      </span>
    </a-table>
    <div>
      <a-modal :title="(department_id ? '编辑' : '新增') + '科室'" :visible="visible" ok-text="提交" cancel-text="取消"
        @ok="submitForm" @cancel="handleCancel">
        <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="Form" :model="form" :rules="Rules">
          <a-form-model-item prop="name" label="科室名称：">
            <a-input v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="is_wd" label="温度：">
            <a-radio-group name="radioGroup" v-model="form.is_wd">
              <a-radio :value="-1">
                关闭
              </a-radio>
              <a-radio :value="1">
                低温
              </a-radio>
              <a-radio :value="2">
                高温
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item prop="is_sound" label="声音：">
            <a-switch checked-children="开" un-checked-children="关" v-model="form.is_sound" />
          </a-form-model-item>
          <a-form-model-item prop="wifi_name" label="WIFI账号：">
            <a-input v-model="form.wifi_name"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="wifi_password" label="WIFI密码：">
            <a-input v-model="form.wifi_password"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
    <div>
      <a-modal title="方案编辑" :visible="planVisible" ok-text="提交" cancel-text="取消" @ok="submitPlan"
        @cancel="planHandleCancel">
        <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" ref="Form" :model="plan" :rules="Rules">
          <a-form-model-item prop="name" label="科室名称：">
            <a-input v-model="form.name"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="is_wd" label="温度：">
            <a-radio-group name="radioGroup" v-model="form.is_wd">
              <a-radio :value="-1">
                关闭
              </a-radio>
              <a-radio :value="1">
                低温
              </a-radio>
              <a-radio :value="2">
                高温
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item prop="is_sound" label="声音：">
            <a-switch checked-children="开" un-checked-children="关" v-model="form.is_sound" />
          </a-form-model-item>
          <a-form-model-item prop="wifi_name" label="WIFI账号：">
            <a-input v-model="form.wifi_name"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="wifi_password" label="WIFI密码：">
            <a-input v-model="form.wifi_password"></a-input>
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  domain
} from '@/siteInfo.js'

export default {
  data() {
    return {
      query: {
        page: 1,
        limit: 10
      },
      pagetotal: 0,
      hospitals: [],
      hospital_id: null,
      visible: false,
      planVisible: false,
      department_id: null,
      plan: {
        plan_id: '7030423829704871936',
      },
      columns: [{
        title: '序号',
        customRender: (text, record, index) => index + 1
      },
      {
        title: '医院名称',
        dataIndex: 'hospital_name'
      },
      {
        title: '科室',
        dataIndex: 'name'
      },
      {
        title: '温度',
        scopedSlots: {
          customRender: 'is_wd'
        }
      },
      {
        title: '声音',
        scopedSlots: {
          customRender: 'is_sound'
        }
      },
      {
        title: '操作',
        scopedSlots: {
          customRender: 'action'
        }
      }
      ],
      data: [],
      form: {},
      Rules: {
        name: [
          {
            required: true,
            message: '填写医院名称',
            trigger: 'blur'
          }
        ],
        is_wd: [
          {
            required: true,
            message: '请选择温度',
            trigger: 'blur'
          }
        ],
        is_sound: [
          {
            required: true,
            message: '声音选择',
            trigger: 'blur'
          }
        ],
        wifi_name: [
          {
            required: true,
            message: '输入WiFi账号',
            trigger: 'blur'
          }
        ],
        wifi_password: [
          {
            required: true,
            message: '输入WiFi密码',
            trigger: 'blur'
          }
        ],
      },
    }
  },
  created() {
    if (this.$route.query.id > -1) {
      this.hospital_id = this.$route.query.id
    }
    this.getList()
  },
  methods: {
    Reset() {
      this.query = {
        page: 1,
        limit: this.query.limit,
      }
      this.getList()
    },
    changeSound(id) {
      this.$post(domain + '/admin/v1/hospital/changeSound', { department_id: id }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    toEdit(item) {
      this.visible = true
      this.department_id = item.department_id
      this.form = {
        name: item.name,
        is_wd: item.is_wd,
        is_sound: item.is_sound == 1 ? true : false,
        wifi_name: item.wifi_name,
        wifi_password: item.wifi_password,
      }
    },
    add() {
      this.visible = true
      this.form.wifi_name = "up"
      this.form.wifi_password = "12345678"
      this.form.is_sound = false
      this.form.is_wd = 1
    },
    handleCancel() {
      this.initForm()
    },
    initForm() {
      this.visible = false
      this.form = {}
      this.department_id = null
    },
    submitForm() {
      this.$refs.Form.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          let url = this.department_id ? 'departmentEdit' : 'departmentAdd'
          this.form.hospital_id = this.hospital_id
          if (this.department_id) {
            this.form.department_id = this.department_id
          }
          this.$post("hospital/" + url, this.form)
            .then(res => {
              let { code, msg, data } = res
              this.confirmLoading = false
              if (code == 0) {
                this.$message.success(msg, 1.5)
                this.initForm()
                this.getList()
              } else {
                this.$message.error(msg, 1.5)
              }
            })
            .catch(err => {
              this.confirmLoading = false
            })
        }
      })
    },
    del(id) {
      this.$post(domain + '/admin/v1/hospital/departmentDel', { department_id: id }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    push(id) {
      this.$post(domain + '/admin/v1/hospital/wifiPush', { department_id: id }).then(res => {
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.getList()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getList() {
      this.query.hospital_id = this.hospital_id
      this.$post(domain + '/admin/v1/hospital/departmentList', this.query).then(res => {
        if (res.code === 0) {
          this.data = res.data.list
          this.pagetotal = res.data.total
          console.log('datadatadatadata', this.data)
        } else {
          this.$message.error(res.msg)

        }
      })
    },
    listChange(e) {
      this.query.page = e.current
      this.getList();
    },
    handleSearch(query) {
      this.query.page = 1
      this.customer_id = null
      this.getList();
      console.log(query)
    },
  }
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-left: 8px;

  .line {
    width: 5px;
    height: 14px;
    background-color: #4E80F8;
  }

  .title {
    color: #383F50;
    font-size: 16px;
    margin-left: 5px;
  }
}

.img-qr {
  height: 30px;
  cursor: pointer;
}
</style>

<style lang="less">
.form-search {
  .ant-form-item-label {
    width: 70px;
  }
}
</style>
